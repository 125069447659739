import React from 'react';
import Fade from 'react-reveal/Fade';

import Layout from 'components/layout';
import SEO from 'components/seo';
import Header from 'components/Header';
import SectionTwo from 'components/Sections/SectionTwo';
import SectionThree from 'components/Sections/SectionThree';
import SectionFour from 'components/Sections/SectionFour';
import SectionFive from 'components/Sections/SectionFive';
import SectionSix from 'components/Sections/SectionSix';
import LatestNews from 'components/Sections/LatestNews';


const Home = ({ data, location, ...props }) => {
  const { markdownRemark: { frontmatter } } = data;
  const { header } = frontmatter;

  return (
    <Layout>
      <SEO
        lang={frontmatter.lang}
        title={frontmatter.seo.title}
        description={frontmatter.seo.description}
        image={frontmatter.seo.image}
        tags={frontmatter.seo.tags}
      />
      <Header>
      <div className="ss_banner_main">
        <div className="row">
          <div className="col-lg-5">
            <div className="ss_banner_left">
              <Fade bottom>
                <h2>{header.label}</h2>
                <h1>{header.headden}</h1>
              </Fade>
            </div>
          </div>
          <div className="col-lg-7">
            <div className="ss_banner_right">
              <img className="img-fluid ss_cloud_one" src={header.image} alt="banner1_img" />
              <img className="img-fluid ss_cloud" src="/assets/images/cloud.png" alt="cloud" />
            </div>
          </div>
        </div>
      </div>
      </Header>
      <SectionTwo page={frontmatter.features} />
      <SectionThree page={frontmatter.service} />
      <SectionFive page={frontmatter.product_features} />
      <SectionFour page={frontmatter.product_demo} />
      <SectionSix />
      <LatestNews />
    </Layout>
  )
}

export default Home
