import React from 'react';
import classNames from 'classnames';
import Zoom from 'react-reveal/Zoom';
import Fade from 'react-reveal/Fade';
import Link from 'components/Link';


 const SectionFive = ({
   page
 }) => (
    <section className="ss_section_five ss_section_sec_bg spacer_top spacer_bottom">
      <div className="container-fluid"> 
        <h3 className="ss_h3_center text-center">{page.label}</h3>
        <h1 className="text-center mb-5">{page.description}</h1>
        <div className="row"> 
          <div className="col-xl-4 col-lg-6">
            <div className="ss_four_left">
              {page.features &&
              page.features.map((featur, i) => i <= 2 && (
                <Fade bottom delay={i * 10}>
                  <Link to={`/products?cat=${i+1}`}>
                    <div
                      className={classNames(
                        'ss_box_bg',
                        (i > 0 && 'mt-4')
                      )}
                    >
                      <div className="row">
                        <div className="col-3">
                          <div className="ss_four_one">
                            <img className="img-fluid" src={featur.image} alt="featur" />
                          </div>
                        </div>
                        <div className="col-9">
                          <div className="ss_four_two">
                            <h2>{featur.title}</h2>
                            <p>{featur.description}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </Fade>
              ))}
            </div>
          </div>
          <div className="col-xl-4 col-lg-6 order-xl-last mt-lg-0 mt-md-4">
            <div className="ss_four_left">
              {page.features &&
              page.features.map((featur, i) => i > 2 && (
                <Fade bottom delay={i * 10}>
                  <Link to={`/products?cat=${i+1}`}>
                    <div
                      className={classNames(
                        'ss_box_bg',
                        (i !== 3 && 'mt-4')
                      )}
                    >
                      <div className="row">
                        <div className="col-3">
                          <div className="ss_four_one">
                            <img className="img-fluid" src={featur.image} alt="featur" />
                          </div>
                        </div>
                        <div className="col-9">
                          <div className="ss_four_two">
                            <h2>{featur.title}</h2>
                            <p>{featur.description}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </Fade>
              ))}
            </div>
          </div>
          <div className="col-xl-4 offset-xl-0 col-lg-6 offset-lg-3">
            <div className="ss_easy_use">
            <Zoom bottom>
              <img className="img-fluid" src={page.image} alt="page" />
            </Zoom>
            </div>
          </div>
        </div>
      </div>
    </section>
 )

 export default SectionFive;
 