import React from 'react'
import Fade from 'react-reveal/Fade';
import i18nx from 'components/locale/i18nx';
import Link from 'components/Link';


const SectionFour = ({
  page,
}) => (
  <section className="ss_section_four spacer_top spacer_bottom">
    <div className="container-fluid"> 
      <div className="row">
        <div className="col-lg-6 align-self-center">
          <div className="ss_five_right wow fadeIn">
            <h3>{page.label}</h3>
            <h1>{page.headden}</h1>
            <p className="pb-4">{page.description}</p>
            <Link to={page.link} className="ss_btn">
              {i18nx('purchase_now')}
            </Link>
          </div>
        </div>
        <div className="col-lg-6 align-self-center">
          <div className="ss_four_right wow fadeIn">
            <Fade delay={200}>
              <img className="img-fluid ss_four_shadow" src={page.image} alt="page" title="page" />
            </Fade>
          </div>
        </div>
      </div>
    </div>
  </section>
)

export default SectionFour;
