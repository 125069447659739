import React from 'react';
import { graphql } from 'gatsby';
import Home from 'templates/Home';

const HomePage = (props) => {
  return (
    <Home { ...props } />
  )
}

export const pageQuery = graphql`
  query Home($lang: String!) {
    markdownRemark(frontmatter: { lang: { eq: $lang }, templateKey: { eq: "Home"}}) {
      id
      frontmatter {
        title
        lang
        templateType
        header {
          headden
          image
          label
          link
        }
        features {
          description
          image
          title
        }
        service {
          description
          headden
          image
          label
          link
        }
        product_demo {
          description
          headden
          image
          label
          link
        }
        product_features {
          label
          headden
          image
          features {
            description
            image
            title
          }
        }
        seo {
          description
          image
          title
          tags
        }
      }
    }
  }
`

export default HomePage;
