import React from 'react'
import { ProductsQuery } from 'components/Products/query';
import Link from 'components/Link';
import i18nx from 'components/locale/i18nx';


const SectionSix = () => {
  const { data } = ProductsQuery();
  return (
    <section className="ss_section_six ss_section_sec_bg spacer_top spacer_bottom">
      <div className="container-fluid"> 
        <h3 className="ss_h3_center text-center">{i18nx('new_products')}</h3>
        <h1 className="text-center mb-5">{i18nx('top_rated_products')}</h1>
        <div className="row justify-content-center"> 
          {data.map(({ node }, i) => i <= 3 && (
            <div key={i} className="col-lg-3 col-md-6 col-sm-6">
              <div className="ss_six_product">
              <Link slug={node.fields.slug}>

                <div className="ss_six_p_img ss_box_bg"
                  style={{
                    height: 400,
                  }}>
                  <img className="img-fluid" src={node.frontmatter.wrapper} alt="wrapper" title="wrapper" />
                </div>
                </Link>
                  <Link slug={node.fields.slug}>
                <div className="ss_product_content ss_box_bg">
                    <h2>{node.frontmatter.title} <span>{node.frontmatter.price}</span></h2>
                  <p>{node.frontmatter.short_description}</p>
                </div>
                  </Link>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default SectionSix;
