import { useStaticQuery, graphql } from "gatsby"
import { languageKey, storage } from '../helper';


export const ProductsQuery = () => {
  const { allMarkdownRemark } = useStaticQuery(
    graphql`
      query {
        allMarkdownRemark(filter: {frontmatter: {templateType: {eq: "product"}}}) {
          edges {
            node {
              id
              fields {
                lang
                slug
              }
              frontmatter {
                templateType
                category
                title
                wrapper
                short_description
                price
              }
            }
          }
        }
      }
    `
  )

  console.log(storage.Locale)
  console.log(languageKey, 'languageKey')
  const getbyLanguage = allMarkdownRemark.edges.filter(
    data => data.node.fields.lang === storage.Locale);

  return {
    data: getbyLanguage || [],
  };

}