import Link from 'components/Link';
import React from 'react'
import Fade from 'react-reveal/Fade';


const SectionThree = ({
  page,
}) => (
  <section className="ss_section_three spacer_bottom">
    <div className="container-fluid"> 
      <div className="row">
        <div className="col-lg-6">
          <div className="ss_three_left">
            <Fade delay={200}>
              <img className="img-fluid" src={page.image} alt="page" />
            </Fade>
          </div>
        </div>
        <div className="col-lg-6 align-self-center">
          <div className="ss_three_right">
            <h3>{page.label}</h3>
            <h1>{page.headden}</h1>
            <p className="pb-4">{page.description}</p>
            <Link to={page.link} className="ss_btn">
              {page.label}
            </Link>
          </div>
        </div>
      </div>
    </div>
  </section>
)

export default SectionThree;
